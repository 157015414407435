.homepage{
    background: #F4FAFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.homeDesign{
    width: 35%;
    height: 100%;
    flex-basis: fit-content;
}

.homeContainer{
    border-radius: 16px;
    background: #DCEBE4;
    border: 1px solid var(--Stromboli-200, #B8D7C9);
    box-shadow: '4px 4px 8px rgba(39, 58, 52, 0.16)';
    height: 75%;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap:4%; */
}

.homeContainerSection{
    margin: 6%;
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 44px;

}

.homeContainerTopSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeContainerTopSectionTxt{
    color: #325549;
font-family: Merriweather;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;

}

.homeContainerBottompSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap:24px;
}

.bottomSectionBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.bottomSectionBoxContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.bottomSectionBoxTxt1{
    color: var(--Dell-500, #6FA240);
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.bottomSectionBoxTxt2{
    color: var(--Stromboli-950, #12211C);
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.bottomSectionBoxTxt3{
    color: #646B69;
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.buttonSection{
    display: flex;
padding: 12px 16px;
align-items: center;
gap: 10px;
border-radius: 22px;
background: linear-gradient(90deg, #6FA240 0%, #55812F 100%);
box-shadow: 4px 4px 16px 0px rgba(111, 162, 64, 0.20);
border: none;
cursor: pointer;
color: #FFF;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.homeContainerSecondSection{
    margin: 6%;
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
}

.secondSectionTxt1{
    color: var(--Stromboli-950, #12211C);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.secondSectionTxt2{
    color: var(--Stromboli-950, #12211C);
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: normal;
}