.complainPage{
    background: #F4FAFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.complainDesign{
    width: 50%;
    height: 100%;
    flex-basis: fit-content;
    position: relative;
}
.complainContainer{
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 16px;
    border: 1px solid var(--Stromboli-200, #B8D7C9);
    background: var(--Stromboli-100, #DCEBE4);
    box-shadow: 4px 4px 16px 0px rgba(85, 129, 47, 0.20);
    height: 72%;
    width: 56%;
    position: absolute;
    left: 20.5%;
}


.complainContainerHeaderSection{
    color: var(--Dell-500, #6FA240);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.complainContainerMiddleSection{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px;
    height: 80%;
}

.middleSectionBox{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.middleSectionBoxContainer{
    display: flex;
    width: 50%;
    height: 52px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    flex-shrink: 0;
}

.middleSectionBoxContainerTxt{
    color: var(--Neutral-Grey-950, #23272E);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; /* 17.5px */
}

.middleSectionBoxContainerTxtBox{
    width: 90%;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--Neutral-Grey-400, #8794A9);
    background: var(--Neutral-Grey-50, #F6F7F9);
}

.middleSectionBoxContainerTxtBox1{
    width: 95%;
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--Neutral-Grey-400, #8794A9);
    background: var(--Neutral-Grey-50, #F6F7F9);
}

.middleSectionBoxContainerTxtArea{
    width: 96%;
    display: flex;
    height: 64px;
    padding: 6px 10px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid var(--Neutral-Grey-400, #8794A9);
    background: var(--Neutral-Grey-50, #F6F7F9);
}

.veticleLine{
    width: 98%;
    height: 2px;
    margin-top: 12px;
    background: #B8D7C9;
}

.complainContainerEndSection{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    height: 10%;
}

.cancelBtn{
    display: flex;
    min-width: 110px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    border: 1px solid var(--Dell-500, #6FA240);
    box-shadow: 4px 4px 16px 0px rgba(111, 162, 64, 0.20);
    color: var(--Dell-500, #6FA240);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
}

.submitBtn{
    display: flex;
    min-width: 110px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 22px;
    background: linear-gradient(90deg, #6FA240 0%, #55812F 100%);
    box-shadow: 4px 4px 16px 0px rgba(111, 162, 64, 0.20);
    color: var(--White, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    cursor: pointer;
}

.suggestionContainer{
    position: absolute;
    top: 100%; 
    left: 0;
    width: 95%;
    max-height: 120px; 
    overflow-y: auto; 
    background-color: #fff; 
    border: 1px solid #ccc; 
    z-index: 1;
}


.suggestionContainer::-webkit-scrollbar {
    width: 6px; /* Adjust the width to make it thin */
    height: 6px;
}
.suggestionContainer::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Color of the scrollbar thumb */
}
.suggestionContainer::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Color of the scrollbar track */
}
.suggestionContainer::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Color of the scrollbar thumb on hover */
}

.suggestions {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .suggestions li {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    background:var(--Stromboli-100, #DCEBE4);
    margin: 1px;
    color: var(--Blue-1, #003F8D);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #C8CFDC;
  }
  
  .suggestions li:hover {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    background: var(--Stromboli-100, #51a07b);
    margin: 1px;
    color: var(--White, #FFF);
  }
  
  .suggestions li.selected {
    padding: 5px 10px 5px 10px;
    cursor: pointer;
    transition: background-color 0.2s;
    background: var(--S_Blue1, #51a07b);
    margin: 1px;
    color: var(--White, #FFF);
    display: block;
    height: auto;
  }

  .dialogTitle{
    display: flex;
    flex-direction: column;
    gap:8px;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  .tiltleHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    }

    .tiltleHeaderTxt{
        color: var(--Dell-500, #6FA240);

    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    }

    .tiltleHeaderSubTxt{
        color: var(--Stromboli-950, #12211C);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal; 
    }

    .dialogContent{
        display: flex;
        flex-direction: column;
        gap:8px;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .dialogContentTxt{
        color: #646B69;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    }

    .dialogContentSubTxt{
        display: flex;
        padding: 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background: #EDF0EE;
        color: #646B69;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }